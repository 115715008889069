.subscribe-container {
    text-align: center;
  }
  
  .subscribe-form input {
    margin: 10px;
    padding: 10px;
    width: 80%;
    max-width: 300px;
  }
  
  .subscribe-form button {
    margin-top: 10px;
    padding: 10px 20px;
    background-color: #333;
    color: white;
    border: none;
    cursor: pointer;
  }
  
  .subscribe-form button:hover {
    background-color: #555;
  }
  