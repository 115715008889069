.banner-slider {
    width: 100%;
    position: relative;
  }
  
  .banner-slider__slide {
    width: 100%;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    position: relative;
  }
  
  .banner-slider__content {
    padding: 20px;
    color: white;
    text-align: left;
    max-width: 40%;
    z-index: 1;
  }
  
  .banner-slider__content h1 {
    font-size: 2.5rem;
    font-weight: bold;
  }
  
  .banner-slider__content p {
    font-size: 1.2rem;
    margin-top: 10px;
  }
  
  /* Responsive styling to center text on small screens */
  @media (max-width: 768px) {
    .banner-slider__slide {
      justify-content: center;
      text-align: center;
    }
  
    .banner-slider__content {
      text-align: center;
      max-width: 100%;
    }
  }
  
  /* Custom dot styles for slide navigation */
  .slick-dots {
    bottom: 20px;
  }
  
  .slick-dots li {
    margin: 0 5px;
  }
  
  .custom-dot {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .custom-dot img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 50%;
  }
  