/* components/Navbar.css */

.navbar-link {
  position: relative;
  padding: 0.5rem;
  text-decoration: none;
  cursor: pointer;
}

.navbar-link::after {
  content: '';
  display: block;
  width: 100%;
  height: 2px; /* Thickness of the bar */
  background-color: #84c5c9; /* Light green color */
  position: absolute;
  left: 0;
  bottom: -5px; /* Position the bar below the link */
  transform: scaleX(0);
  transition: transform 0.3s ease-in-out;
}

.navbar-link:hover::after {
  transform: scaleX(1);
}

/*#053744*/
/*#84c5c9*/
