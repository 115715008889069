@tailwind base;
@tailwind components;
@tailwind utilities;

@import "./Assets/styles/Banner.scss";
@import "./Assets/styles/Navbar.scss";
@import "./Assets/styles/main.scss";
@import "./Assets/styles/Diagrams.scss";
@import "./Assets/styles/SectionCard.scss";
@import "./Assets/styles/Aboutus.scss";
@import "./Assets/styles/Approach.scss";
@import "./Assets/styles/Contactus.scss";
@import "./Assets/styles/Slider.scss";
@import "./Assets/styles/Impact.scss";
@import "./Assets/styles/MailchimpForm.scss";

/* @import "./Assets/styles/Homepage.scss"; */
