/* Contact card container */
.contact-card {
    background-color: white;
    border-radius: 10px;
    padding: 30px;
    margin: 0 auto;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    text-align: center;
  }
  
  .contact-card__title {
    font-size: 1.8rem;
    font-weight: bold;
    margin-bottom: 20px;
  }
  
  .contact-card__info {
    margin-bottom: 20px;
  }
  
  .contact-card__item {
    display: flex;
    gap: 10px;
    margin-bottom: 15px;
  }
  
  .contact-card__icon {
    color: #84c5c9; /* Light green color */
  }
  
  .contact-card__socials {
    margin-bottom: 30px;
  }
  
  .contact-card__social-icons a {
    color: #84c5c9; /* Light green color */
    margin: 0 4px;
    transition: color 0.3s ease;
  }
  
  .contact-card__social-icons a:hover {
    color: #053744; /* Dark green on hover */
  }
  
  .contact-card__donate h3 {
    margin-bottom: 10px;
  }
  
  .contact-card__donate p {
    font-size: 1rem;
    margin-bottom: 20px;
  }
  
  .donate-button {
    padding: 10px 20px;
    background-color: #84c5c9;
    color: white;
    border: none;
    border-radius: 5px;
    font-size: 1rem;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .donate-button:hover {
    background-color: #053744;
  }
  