/*.about-us-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 50px;
    background-color: white;
  }
  
  .about-us-left {
    width: 40%;
  }
  
  .about-us-title {
    font-size: 2.5rem;
    font-weight: 600;
    margin: 0;

    span {
      position: relative;
      width: 100%;
      padding-left: 20px;
      padding-right: 10px;
      background-color: #0537442e;
      border-radius: 20px;
      color: #053744;
      &::before {
        position: absolute;
        left: 0;
        top: 50%;
        content: "";
        height: 4px;
        width: 12px;
        border-radius: 20px;
        background-color: #053744;

      }
    }
  }
  
  .about-us-right {
    width: 60%;
    position: relative;
  }
  
  .about-us-description {
    position: relative;
    z-index: 3;
    font-size: 1.2rem;
    line-height: 1.8;
    padding: 20px;
  }
  
  /* Clipped rectangles
  .clipped-rectangles {
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
  }
  
  /* Light green rectangle in the background 
  .light-green-bg {
    position: absolute;
    top: 40px;
    left: 20px;
    width: 90%;
    height: 100%;
    background-color:  rgba(158, 210, 99,0.3); /* Transparent dark green 
    clip-path: polygon(0 0, 100% 10%, 90% 100%, 10% 90%);
    border-radius: 20px;
    z-index: 2;
  }
  
  /* Dark green rectangle with rounded corners on top
  .dark-green-bg {
    position: absolute;
    top: 40px;
    left: 20px;
    width: 90%;
    height: 100%;
    background-color: rgba(47, 79, 47,0.3);  Transparent dark green 
    clip-path: polygon(0 0, 100% 10%, 90% 100%, 10% 90%);
    border-radius: 20px;
    z-index: 2;
  }
    */

.column {
  width: 30%;
  position: relative;
  text-align: center;
}

.title-box {
  background-color: #84c5c9; /* Light green background */
  padding: 15px;
  border-radius: 15px;
  font-size: 1.5rem;
  font-weight: bold;
  color: white;
}

.description {
  font-size: 1.1rem;
  padding: 10px;
  background-color: #f9f9f9;
  border-radius: 10px;
  position: relative;
  z-index: 2;
}

.wavy-line {
  margin: 5px auto;
  display: flex;
  justify-content: center;
  svg {
    width: 100px;
    height: 25px;
  }
}

/*About siingle*/
/* About Us Page Container */
.about-us-page {
  font-family: Arial, sans-serif;
}

/* Header Section */
.about-us-header {
  width: 100%;
  height: 28vh; /* 20% of the viewport height */
  position: relative;
  background-image: url("../images/banner/banner6.jpg"); /* Background image */
  background-size: cover;
  background-position: center;
  background-attachment: fixed;
}

/* Overlay */
.overlay {
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent overlay */
  display: flex;
  justify-content: center;
  align-items: flex-end; /* Align title at the bottom */
}

/* Page Title */
.page-title {
  color: white;
  font-size: 2.5rem;
  font-weight: bold;
  padding-bottom: 20px; /* Position title at the bottom of the overlay */
}

/* Main Content */
.about-us-content {
  padding: 40px;
  font-size: 1.2rem;
  line-height: 1.8;
  text-align: justify;
}

/*themantic */

